@import url(https://fonts.googleapis.com/css?family=Montserrat|Roboto&display=swap);
/**
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126
 * License: none (public domain)
 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  color: #404040;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  overflow-x: hidden;
}

* {
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
}

body {
  color: #404040;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

a,
label,
button {
  cursor: pointer;
}

input,
select {
  font-family: inherit;
}

.snip-layout.snip-active {
  z-index: 2000;
}

/* Animations */
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

/**
 * Typography
 */

html,
.font-family--body {
  font-family: 'Roboto', 'Franklin Gothic Medium', Tahoma, Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-family--title {
  font-family: 'Montserrat', 'Roboto', 'Franklin Gothic Medium', Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 400;
  /* normal */
  margin: 0;
}

h1,
.font-size--1 {
  font-size: 3.1428rem;
  /* 44px */
  line-height: 1.2727em;
  /* 56px */
}

h2,
.font-size--2 {
  font-size: 2.2857rem;
  /* 32px */
  line-height: 1.125em;
  /* 36px */
}

h3,
.font-size--3 {
  font-size: 1.7142rem;
  /* 24px */
  line-height: 1.25em;
  /* 30px */
}

h4,
.font-size--4 {
  font-size: 1.2857rem;
  /* 18px */
  line-height: 1.3333em;
  /* 24px */
}

h5,
p,
body,
.font-size--5 {
  font-size: 1rem;
  /* 14px */
  line-height: 1.5em;
  /* 21px */
}

h6,
small,
.font-size--6 {
  font-size: 0.8571rem;
  /* 12px */
  line-height: 1.1667em;
  /* 14px */
}

caption,
.caption {
  font-size: 0.7143rem;
  /* 10px */
  line-height: 1.2em;
  /* 12px */
}

.font-family--mono,
code {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
}

p+p {
  margin-top: 2ex;
}

a {
  font-size: inherit;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.noshow,
.sr-only,
.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.link--reset {
  color: inherit;
  text-decoration: none;
}

