html {
  color: #404040;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  overflow-x: hidden;
}

* {
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
}

body {
  color: #404040;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

a,
label,
button {
  cursor: pointer;
}

input,
select {
  font-family: inherit;
}

.snip-layout.snip-active {
  z-index: 2000;
}

/* Animations */
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}
